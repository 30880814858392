<template>
  <div class="container">
    <div class="leftBox">
      <div
        class="item"
        v-for="(item, index) in typeList"
        :key="index"
        @click="handleClick(index)"
        :class="{ active: index == typeIndex }"
      >{{ item.name }}</div>
    </div>
    <div class="rightBox">
      <el-button type="primary" @click="handleAdd" class="addBtn">新增类别</el-button>
      <el-table :data="tableData.slice((page - 1) * limit, page * limit)" border style="width: 100%" height="calc(100% - 102px)">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="iocode" label="类别名称"></el-table-column>
        <el-table-column prop="iodesc" label="类别描述"></el-table-column>
        <el-table-column prop="num" label="类别顺序"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="editBox" @click="handleEdit(scope.row)">
              <el-button type="success" icon="el-icon-edit" circle></el-button>
              <span>修改</span>
            </span>
            <span class="deleteBox" @click="handleDelete(scope.row)">
              <el-button type="danger" icon="el-icon-delete" circle></el-button>
              <span>删除</span>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 新增弹框 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="360px">
      <el-form ref="form" :model="form" :rules="rules" label-width="auto">
        <el-form-item label="类别名称" prop="iocode">
          <el-input v-model="form.iocode"></el-input>
        </el-form-item>
        <el-form-item label="类别描述" prop="iodesc">
          <el-input v-model="form.iodesc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm(true)">
          保存并新增
          <i class="el-icon-circle-plus-outline" style="margin-left: 5px"></i>
        </el-button>
        <el-button type="primary" @click="submitForm(false)">保存</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    
<script>
import { get, post, deleteMethod, put } from "../../../api/http.js";
export default {
  data() {
    return {
      typeList: [
        {
          name: "活动类型",
        },
        {
          name: "输入类别",
        },
        {
          name: "输出类别",
        },
        {
          name: "周期",
        },
      ],
      typeIndex: 0,
      tableData: [],
      page: 1,
      limit: 10,
      total: 0,
      title: "",
      dialogVisible: false,
      form: {},
      oldForm: {},
      rules: {
        iocode: [{ required: true, message: "请输入类别名称", trigger: "blur" },],
        iodesc: [{ required: true, message: "请输入类别描述", trigger: "blur" }],
      },
    };
  },
  created() {
    this.handleClick(this.typeIndex);
  },
  methods: {
    handleClick(index) {
      this.typeIndex = index;
      let url = "";
      switch (this.typeIndex) {
        case 0:
          url = "/api/ProcBaseInfo/GetM";
          break;
        case 1:
          url = "/api/ProcBaseInfo/GetIn";
          break;
        case 2:
          url = "/api/ProcBaseInfo/GetOut";
          break;
        case 3:
          url = "/api/ProcBaseInfo/GetR";
          break;
      }
      get(url).then((res) => {
        if(res.code == 200){
          this.tableData = res.data;
          this.total = res.count;
        }
      });
    },
    // 表单重置
    reset() {
      this.form = {
        iocode: "",
        iodesc: "",
      };
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
    },
    handleAdd() {
      this.reset();
      this.dialogVisible = true;
      this.title = "新增类别";
    },
    handleEdit(row) {
      this.oldForm = JSON.parse(JSON.stringify(row));
      this.form = row;
      this.dialogVisible = true;
      this.title = "修改类别";
    },
    // 保存按钮
    submitForm(flag) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.title == "修改类别") {
            put('/api/ProcBaseInfo?num='+ this.oldForm.num + '&iocode=' + this.oldForm.iocode,this.form).then(res =>{
              if(res.code == 200){
                this.$message.success("修改成功");
                this.dialogVisible = false;
                this.handleClick(this.typeIndex);
              }
            })
          } else {
            let url = "";
            switch (this.typeIndex) {
              case 0:
                url = "/api/ProcBaseInfo/PostM";
                break;
              case 1:
                url = "/api/ProcBaseInfo/PostIn";
                break;
              case 2:
                url = "/api/ProcBaseInfo/PostOut";
                break;
              case 3:
                url = "/api/ProcBaseInfo/PostR";
                break;
            }
            post(url, this.form).then((res) => {
              if (res.code == 200) {
                this.$message.success("新增成功");
                this.dialogVisible = flag ? true : false;
                this.handleClick(this.typeIndex);
                this.reset();
              }
            });
          }
        }
      });
    },
    // 删除
    handleDelete(row) {
      this.$confirm(`此操作将删除${row.iocode}, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteMethod('/api/ProcBaseInfo?num='+ row.num + '&iocode=' + row.iocode).then(res =>{
            if(res.code == 200){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.handleClick(this.typeIndex);
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    handleSizeChange(val) {
      this.limit = val;
    },
    handleCurrentChange(val) {
      this.page = val;
    },
  },
};
</script>
    
<style lang="less" scoped>
@import "../../../assets/style/optimizeTable.less";
.container {
  width: 100%;
  height: 100%;
  display: flex;
  .leftBox {
    width: 260px;
    height: 293px;
    background: #ffffff;
    margin-right: 6px;
    padding: 32px 38px 0 36px;
    box-sizing: border-box;
    .item {
      width: 180px;
      height: 44px;
      background: #e8ebf4;
      border-radius: 6px;
      border: 1px solid #dcdcdc;
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 14px;
      color: #333333;
      text-align: center;
      line-height: 44px;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .active {
      background: #5a8bff;
      color: #ffffff;
    }
  }
  .rightBox {
    // flex: 1;
    width: calc(100% - 266px);
    background: #ffffff;
    .addBtn {
      margin: 12px 0 10px 14px;
    }
    .editBox {
      margin-right: 32px;
      span {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #22bf4a;
        margin-left: 4px;
      }
    }
    .deleteBox {
      span {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #f33434;
        margin-left: 4px;
      }
    }
  }
}
</style>